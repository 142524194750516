import scalaLogo from "../../images/scala.svg";
import functionalLogo from "../../images/functional.svg";
import kotlinLogo from "../../images/kotlin.svg";
import typeScriptLogo from "../../images/typescript.svg";
import wfhLogo from "../../images/wfh.svg";
import rustLogo from "../../images/rust.svg";
import { where } from "firebase/firestore/lite";


const functional = {
  title: `FunctionalJobs`,
  description: "Functional Jobs",
  logo: functionalLogo,
  keyword: `Functional`,
  siteUrl: `FunctionalJobs.dev`,
  shareImage: `functionaljobs_qdghvq`,
  price: `$99`,
  displayFilters: false,
  displayLogo: true,
  social: {}
}

const scala = {
  title: `ScalaJobs`,
  description: "Scala Jobs",
  logo: scalaLogo,
  keyword: `Scala`,
  siteUrl: `ScalaJobs.com`,
  shareImage: `scalajobs_template_ybaij4`,
  price: `$299`,
  displayFilters: false,
  displayLogo: true,
  social: {
    twitter: "https://twitter.com/ScalaJobsDev",
    linkedIn: "https://linkedin.com/company/scalajobs-dev/",
    reddit: "https://reddit.com/r/scalajobs/",
  },
}

const kotlin = {
  title: `KotlinJobs`,
  description: "Kotlin Jobs",
  logo: kotlinLogo,
  keyword: `Kotlin`,
  siteUrl: `KotlinJobs.dev`,
  shareImage: `kotlin_template_e7kbuh`,
  price: `$99`,
  displayFilters: false,
  displayLogo: false,
  social: {},
}

const typeScript = {
  title: `TypeScriptJobs`,
  description: "TypeScript Jobs",
  logo: typeScriptLogo,
  keyword: `TypeScript`,
  siteUrl: `TypeScriptJobs.dev`,
  shareImage: `kotlintemplate_giv048`,
  price: `$99`,
  displayFilters: false,
  displayLogo: false,
  social: {
    twitter: "https://twitter.com/TypeScriptJobs",
    linkedIn: "https://www.linkedin.com/company/typescript-jobs",
  },
}

const wfh = {
  title: `WFHJobs`,
  description: "WFH Developer Jobs",
  logo: wfhLogo,
  keyword: `Remote`,
  siteUrl: `WFHJobs.dev`,
  shareImage: `WFH_Template_tca60p`,
  price: `$99`,
  displayFilters: false,
  displayLogo: false,
  social: {},
}

const rust = {
  title: `RustJobs`,
  description: "Rust Jobs",
  logo: rustLogo,
  keyword: `Rust`,
  siteUrl: `RustJobs.dev`,
  shareImage: `rustjobs_template_ajpsgu`,
  price: `$299`,
  displayFilters: false,
  displayLogo: true,
  social: {
    twitter: "https://twitter.com/rustjobs_dev",
    linkedIn: "https://www.linkedin.com/company/rustjobs-dev",
  },
}

const cms = process.env.WEBSITE === "functionaljobs" ? functional :
  process.env.WEBSITE === "kotlinjobs" ? kotlin :
    process.env.WEBSITE === "typescriptjobs" ? typeScript :
      process.env.WEBSITE === "wfhjobs" ? wfh :
        process.env.WEBSITE === "rustjobs" ? rust :
          scala;

const queryConstraintsWithWebsite = (queryConstraints, tag) => {
  if (tag) {
    return queryConstraints;
  } else if (cms.keyword === "Remote") {
    queryConstraints.push(where("remote", "==", "remote"));
    return queryConstraints;
  } else {
    queryConstraints.push(where("websites", "array-contains", cms.keyword));
    return queryConstraints;
  }
}

const scalaStripeConfig = (process.env.NODE_ENV !== 'production') ?
  { // Test
    key: "pk_test_51IidyHHJUEECEqKCUco65Dt1f6Q6EDZOKEH6gfWzJOBQY4Dz4k72CMIVDrbdNYnphdygQjICdytViV3IiTRNSUYq00N9IT0Btq",
    priceId: "price_1KhpbVHJUEECEqKCc744S8yA",
  } : { // Prod
    key: "pk_live_51IidyHHJUEECEqKCzZR2Z5qYryKTJezO3OjjTTBkHttGVa8XwLLaO0fc9x83UuxP744uXH1bpusnmuRh2XajMUqb00vlYctFnR",
    priceId: "price_1Khpa0HJUEECEqKCX1w7T5SH",
  };

const kotlinStripeConfig = (process.env.NODE_ENV !== 'production') ?
  { // Test
    key: "pk_test_51IidyHHJUEECEqKCUco65Dt1f6Q6EDZOKEH6gfWzJOBQY4Dz4k72CMIVDrbdNYnphdygQjICdytViV3IiTRNSUYq00N9IT0Btq",
    priceId: "price_1JJfl1HJUEECEqKCrf6kj3y1",
  } : { // Prod
    key: "pk_live_51IidyHHJUEECEqKCzZR2Z5qYryKTJezO3OjjTTBkHttGVa8XwLLaO0fc9x83UuxP744uXH1bpusnmuRh2XajMUqb00vlYctFnR",
    priceId: "price_1J6zCPHJUEECEqKCPztmZFU8",
  };

const functionalStripeConfig = (process.env.NODE_ENV !== 'production') ?
  { // Test
    key: "pk_test_51IidyHHJUEECEqKCUco65Dt1f6Q6EDZOKEH6gfWzJOBQY4Dz4k72CMIVDrbdNYnphdygQjICdytViV3IiTRNSUYq00N9IT0Btq",
    priceId: "price_1JJflRHJUEECEqKCQrmVoU3h",
  } : { // Prod
    key: "pk_live_51IidyHHJUEECEqKCzZR2Z5qYryKTJezO3OjjTTBkHttGVa8XwLLaO0fc9x83UuxP744uXH1bpusnmuRh2XajMUqb00vlYctFnR",
    priceId: "price_1J6zFAHJUEECEqKC7RhYlm5W",
  };

const typeScriptStripeConfig = (process.env.NODE_ENV !== 'production') ?
  { // Test
    key: "pk_test_51IidyHHJUEECEqKCUco65Dt1f6Q6EDZOKEH6gfWzJOBQY4Dz4k72CMIVDrbdNYnphdygQjICdytViV3IiTRNSUYq00N9IT0Btq",
    priceId: "price_1JYWY7HJUEECEqKCW20S2iVW",
  } : { // Prod
    key: "pk_live_51IidyHHJUEECEqKCzZR2Z5qYryKTJezO3OjjTTBkHttGVa8XwLLaO0fc9x83UuxP744uXH1bpusnmuRh2XajMUqb00vlYctFnR",
    priceId: "price_1JYWZIHJUEECEqKCDystcU1F",
  };

const wfhStripeConfig = (process.env.NODE_ENV !== 'production') ?
  { // Test
    key: "pk_test_51IidyHHJUEECEqKCUco65Dt1f6Q6EDZOKEH6gfWzJOBQY4Dz4k72CMIVDrbdNYnphdygQjICdytViV3IiTRNSUYq00N9IT0Btq",
    priceId: "price_1Jayh1HJUEECEqKCu7PoYYH1",
  } : { // Prod
    key: "pk_live_51IidyHHJUEECEqKCzZR2Z5qYryKTJezO3OjjTTBkHttGVa8XwLLaO0fc9x83UuxP744uXH1bpusnmuRh2XajMUqb00vlYctFnR",
    priceId: "price_1JayhqHJUEECEqKCllCCMS5X",
  };

const rustStripeConfig = (process.env.NODE_ENV !== 'production') ?
  { // Test
    key: "pk_test_51IidyHHJUEECEqKCUco65Dt1f6Q6EDZOKEH6gfWzJOBQY4Dz4k72CMIVDrbdNYnphdygQjICdytViV3IiTRNSUYq00N9IT0Btq",
    priceId: "price_1KhpbsHJUEECEqKC0Fi2mu8W",
  } : { // Prod
    key: "pk_live_51IidyHHJUEECEqKCzZR2Z5qYryKTJezO3OjjTTBkHttGVa8XwLLaO0fc9x83UuxP744uXH1bpusnmuRh2XajMUqb00vlYctFnR",
    priceId: "price_1KhpcKHJUEECEqKCCfhcmMTd",
  };

const stripeConfig = process.env.WEBSITE === "functionaljobs" ? functionalStripeConfig :
  process.env.WEBSITE === "kotlinjobs" ? kotlinStripeConfig :
    process.env.WEBSITE === "typescriptjobs" ? typeScriptStripeConfig :
      process.env.WEBSITE === "wfhjobs" ? wfhStripeConfig :
        process.env.WEBSITE === "rustjobs" ? rustStripeConfig :
          scalaStripeConfig;

export default cms;

export { queryConstraintsWithWebsite };

export { stripeConfig };