import React, { useState } from "react";
import cms from "../components/cms/cms";
import companies from "./companies";

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <div className="relative bg-gray-900 z-20 shadow">
      <div className="relative bg-gray-900 shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="w-0 flex-1 flex">
              <a href="/" className="inline-flex flex items-center">
                {cms.displayLogo ? <img className="h-10 w-auto mr-2" src={cms.logo} alt={cms.title} /> : <></>}
                <span className="text-2xl tracking-tight leading-10 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600">{cms.title}</span>
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button type="button" onClick={() => toggleExpansion(!isExpanded)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-800 focus:outline-none focus:bg-gray-800 focus:text-gray-500 transition duration-150 ease-in-out">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              {
                cms.keyword === "Scala" || cms.keyword === "Rust" ?
                  <a href="/hiring-solutions"
                    className="text-base leading-6 font-bold text-gray-200 hover:text-gray-50 transition ease-in-out duration-150 flex items-center">
                    Hiring Solutions
                  </a> : <></>
              }
              <a href="/about"
                className="text-base leading-6 font-bold text-gray-200 hover:text-gray-50 transition ease-in-out duration-150 flex items-center">
                About us
              </a>
              {
                cms.keyword === "Scala" || cms.keyword === "Rust" ?
                  <a href="/blog"
                    className="text-base leading-6 font-bold text-gray-200 hover:text-gray-50 transition ease-in-out duration-150 flex items-center">
                    Blog
                  </a> : <></>
              }
              {
                Object.keys(companies).length > 0 ?
                  <a href="/companies"
                    className="text-base leading-6 font-bold text-gray-200 hover:text-gray-50 transition ease-in-out duration-150 flex items-center">
                    Companies
                  </a> : <></>
              }
            </nav>
            {cms.keyword === "Scala" || cms.keyword === "Rust" ?
              <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
                <span className="inline-flex rounded-md shadow-sm">
                  <a href="/post-job" class="whitespace-no-wrap text-base leading-6 font-bold text-gray-200 hover:text-gray-50 transition ease-in-out duration-150 flex items-center">
                    Post Job
                  </a>
                </span>
                <span className="inline-flex rounded-md shadow-sm">
                  <a href="/hiring-solutions" eventCategory="Button" eventAction="Click" eventLabel="Post Job"
                    className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-red-700 transition ease-in-out duration-150">
                    Start Hiring
                  </a>
                </span>
              </div> :
              <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
                <span className="inline-flex rounded-md shadow-sm">
                  <a href="/post-job" eventCategory="Button" eventAction="Click" eventLabel="Post Job"
                    className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-red-700 transition ease-in-out duration-150">
                    Post Job
                  </a>
                </span>
              </div>
            }
          </div>
        </div>

        <div
          className={`absolute top-0 inset-x-0 z-20 p-2 transition transform origin-top-right ${isExpanded ? "block" : "hidden"}`}>
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs bg-white">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <a href="/" className="flex items-center">
                    {cms.displayLogo ? <img className="h-10 w-auto mr-2" src={cms.logo} alt={cms.title} /> : <></>}
                    <span className="text-2xl tracking-tight leading-10 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600">{cms.title}</span>
                  </a>
                  <div className="-mr-2">
                    <button type="button" onClick={() => toggleExpansion(!isExpanded)}
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 row-gap-4 col-gap-8">
                  {
                    cms.keyword === "Scala" || cms.keyword === "Rust" ?
                      <a href="/hiring-solutions"
                        className="text-base leading-6 font-bold text-gray-900 hover:text-gray-700 transition ease-in-out duration-150 flex items-center">
                        Hiring Solutions
                      </a> : <></>
                  }
                  <a href="/about"
                    className="text-base leading-6 font-bold text-gray-900 hover:text-gray-700 transition ease-in-out duration-150 flex items-center">
                    About us
                  </a>
                  {
                    cms.keyword === "Scala" || cms.keyword === "Rust" ?
                      <a href="/blog"
                        className="text-base leading-6 font-bold text-gray-900 hover:text-gray-700 transition ease-in-out duration-150 flex items-center">
                        Blog
                      </a> : <></>
                  }
                  {

                    Object.keys(companies).length > 0 ?
                      <a href="/companies"
                        className="text-base leading-6 font-bold text-gray-900 hover:text-gray-700 transition ease-in-out duration-150 flex items-center">
                        Companies
                      </a> : <></>
                  }
                </div>
                {cms.keyword === "Scala" || cms.keyword === "Rust" ?
                  <div className="space-y-6">
                    <span className="w-full flex rounded-md shadow-md">
                      <a href="/post-job" eventCategory="Button" eventAction="Click" eventLabel="Post Job Mobile Header"
                        className="block w-full rounded-md text-center border border-gray-100 bg-white px-4 py-2 text-base leading-6 font-medium text-red-600 hover:text-red-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                        Post Job
                      </a>
                    </span>
                    <span className="w-full flex rounded-md shadow-sm">
                      <a href="/hiring-solutions" eventCategory="Button" eventAction="Click" eventLabel="Post Job Mobile Header"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-red-700 transition ease-in-out duration-150">
                        Start Hiring
                      </a>
                    </span>
                  </div> :
                  <div className="space-y-6">
                    <span className="w-full flex rounded-md shadow-sm">
                      <a href="/post-job" eventCategory="Button" eventAction="Click" eventLabel="Post Job Mobile Header"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-red-700 transition ease-in-out duration-150">
                        Post Job
                      </a>
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;