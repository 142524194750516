import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import loadable from "@loadable/component";

const Footer = loadable(() => import("./footer"));
const Banner = loadable(() => import("./banner"));

const Layout = ({ children }) =>
  <div className="antialiased bg-gray-100">
    <Header />
    {children}
    <Banner />
    <Footer />
  </div>

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;